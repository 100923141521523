/**
 * Handles all font loading separate from main theme so that fonts are not
 * render-blocking
 *
 * Place @font-face directives at the top of this file
 */

// Font Awesome 5 Pro includes & overrides

$fa-font-path: "../fonts";
@import url("//fonts.googleapis.com/css?family=Proza+Libre:500,500i,600|Work+Sans:400,600,700&display=swap");

@import "@fortawesome/fontawesome-pro/scss/fontawesome",
  "@fortawesome/fontawesome-pro/scss/solid",
  "@fortawesome/fontawesome-pro/scss/regular",
  "@fortawesome/fontawesome-pro/scss/light",
  "@fortawesome/fontawesome-pro/scss/brands";

.fa,
.fas,
.far,
.fal,
.fab {
  // While fonts loading
  .wf-loading & {
    // Prevent FOUT
    color: transparent;
  }
}
